@redA700: #d50000;

.Icon {
  justify-content: flex-end;
}

.CropSelect {
  text-overflow: ellipsis;
}

.DatePicker {
  input,
  button,
  div {
    cursor: pointer;
  }

  width: 100%;

  button {
    padding: 0;
  }
}

div.InputError {
  input {
    color: @redA700;
  }
  svg {
    fill: @redA700;
  }
}
