@redA700: #d50000;

.Error {
  div {
    background-color: @redA700 !important;
  }
}

.ErrorAction {
  background-color: white !important;

  span {
    color: @redA700;
  }
}
