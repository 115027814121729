@blueA700: #2962ff;
@grey400: #bdbdbd;
@grey700: #616161;

@fade: 0.4;
@paperBorderRadius: 24px;

.Root,
.Root * {
  cursor: pointer !important;
}

input.Input {
  padding: 20px 12px 12px;
}

.Year,
.InputAdornment {
  user-select: none;
  pointer-events: none;
}

.Day {
  width: 36px;
  height: 36px;
  margin: 0 2px;

  span {
    font-size: 14px;
    font-weight: bold;
    color: inherit;
  }
}

.Highlight {
  background-color: @blueA700;
  opacity: @fade;

  .Day {
    color: white;
  }
}

.StartHighlight {
  .Highlight();

  opacity: 1;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;

  &.SelectedStart {
    opacity: @fade;
  }
}

.EndHighlight {
  .Highlight();

  opacity: 1;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;

  &.SelectedEnd {
    opacity: @fade;
  }
}

.NonCurrentMonthDay {
  &.Day {
    color: @grey400 !important;
    font-weight: lighter;
  }
}

.HighlightNonCurrentMonthDay {
  &.Day {
    color: @grey700;
  }
}

div.PaperStart {
  overflow: hidden;
  border-radius: 0;
  border-top-left-radius: @paperBorderRadius;
  border-bottom-left-radius: @paperBorderRadius;
}

div.PaperEnd {
  overflow: hidden;
  border-radius: 0;
  border-top-right-radius: @paperBorderRadius;
  border-bottom-right-radius: @paperBorderRadius;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
  .input.Input {
    color: @grey700;
  }
}
