div.imageHolder {
  background-color: #00000029;
  border-radius: 8px;
}

p.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}

.checkbox {
  padding: 0 !important;
  display: block;
}

li.item {
  padding-top: 4px;
  padding-bottom: 4px;
}
