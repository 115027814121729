@redA700: #d50000;

svg.IconError {
  color: @redA700;
}

div.SelectRoot {
  display: inline-flex;
  height: 1.1875em;
}

div.SelectRootError {
  label {
    color: @redA700;
  }
}

div.ListItemText {
  display: inline-flex;
}

div.ListItemIcon {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

div.ListItemText {
  margin-bottom: 0;
  margin-top: 0;
}
