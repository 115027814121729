.group {
  margin: 4px 0;
  width: 100%;
}

.bar {
  height: 100%;
}

.name {
  color: black;
  font-weight: 300;
  font-size: 1em;
  margin: 0 10px 0 0;
}

.inside {
  color: white;
  cursor: default;
  font-weight: 900;
  font-size: 0.7em;
  margin: 0 5px 0 0;
}

.outside {
  color: black;
  cursor: default;
  font-weight: 900;
  font-size: 0.7em;
  margin: 0 0 0 5px;
}
