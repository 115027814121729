.Root {
  background-color: white;
  border: 1px solid #9e9e9e;
  border-radius: 1px;
  z-index: 2;
  transition:
    box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  position: absolute;
  box-sizing: content-box;

  &.Vertical {
    transform: translate(-50%, -50%);

    & button {
      cursor: row-resize;
    }
  }

  &.Horizontal {
    transform: translate(-50%, -50%);

    & button {
      cursor: col-resize;
    }
  }

  &:hover {
    background-color: #eeeeee;
  }

  &:active {
    background-color: #eeeeee;
  }
}

.RelativeWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  & button {
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
}
