.box {
  min-width: 300px;
  max-width: 300px;
  position: relative;
}

div.buttonRow {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px -2px 10px;
}

button.button {
  padding: 0;
  min-width: 0;
  height: 100%;
}

p.buttonText {
  font-size: 14px;
  font-weight: 300;
}

.list {
  max-height: 50vh;
  overflow: auto;

  .narrow-scrollbar();
}

.list ul {
  padding: 0;
}

li.subheader {
  line-height: 28px;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
