.LoadingRoot {
  & hr {
    display: none;
  }
}

.LoadingWrapper {
  padding: 50px;
  text-align: center;
}

.GridList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
