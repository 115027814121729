p.title {
  font-size: 20px;
  font-weight: bold;
}

p.itemTitle {
  font-size: 20px;
  font-weight: 300;
}

p.content {
  font-size: 14px;
}

p.italic {
  font-size: 14px;
  font-style: italic;
}

p.itemSubtitle {
  color: #78a22f;
  font-size: 12px;
}

button.cancel {
  font-size: 14px;
  font-weight: 400;
}

button.delete {
  display: inline-block;
  padding: 0;
  min-height: 0;
  min-width: 0;
  width: 24px;
}
