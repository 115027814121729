.weather-graph {
  position: relative;
  height: 100%;
  width: 100%;
}
.weather-graph .notifications-empty-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.weather-graph .notifications-empty-notice svg {
  margin-left: 5px;
}
