@text-size: 1rem;

.Root {
  width: 100%;
  user-select: none;
  flex: 1 1 auto;
  * {
    box-sizing: border-box;
  }
}

.Editor {
  box-sizing: border-box;
}

.CanvasHolder {
  position: relative !important;
  display: flex;
  margin-bottom: 30px;
  background-color: #eeeeee;
  transition: height 0.1s ease-out;
}
