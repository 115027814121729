.root {
  cursor: pointer;
}

p.text {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.PopoverContainer {
  padding: 12px;
  overflow-y: hidden;
}

div.ListItemText {
  font-size: 0.8rem;
  margin: 0;
}

div.ListItemSecondaryAction {
  margin-right: -8px;

  button {
    padding: 8px;

    svg {
      font-size: 1.2rem;
    }
  }
}

ul.list {
  width: 100%;
  overflow: auto;
  height: 30vh;
  padding: 0;
  .narrow-scrollbar();
}

div.header {
  font-weight: 400;
  font-size: 0.9rem;
  color: #a7a9ac;
  overflow: hidden;
  white-space: nowrap;
}

div.item {
  font-weight: 400;
  font-size: 0.9rem;
  overflow: hidden;
  white-space: nowrap;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
