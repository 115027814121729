div.Root {
  z-index: 10;
}

div.SelectRoot {
  display: inline-flex;
}

div.ListItemText {
  display: inline-flex;
}

div.ListItemIcon {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

li.ListItemText {
  padding-left: 8px;
}

div.ListItemSecondaryAction {
  right: 0;
}

.List {
  max-height: 40vh;
  overflow: auto;
  margin-right: -16px;

  .narrow-scrollbar();
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
