.Root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.CanvasWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.Canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.Hidden {
  display: none;
}
