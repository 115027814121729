div.white,
svg.white {
  color: white;
}
div.black,
svg.black {
  color: black;
}

div.root {
  padding-top: 6px;
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

button.button {
  height: 12px;
  font-size: 8px;
}

div.selector {
  cursor: pointer !important;
  padding-right: 36px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 400;
}

div.select {
  padding-right: 36px;
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: 0.5;
  font-weight: 400;
}
div.disabled,
svg.disabled {
  opacity: 0.6 !important;
}

.checkbox {
  &:hover {
    background-color: transparent !important;
  }
}

label.formLabel {
  margin-left: 6px;
  margin-right: 6px;
}
