@blueGrey800: #37474f;

.Root {
  transform: translate(-50%, 36px) !important;
  max-width: 400px;
  div {
    background-color: white;
    color: @blueGrey800;
  }
}

.PrimaryAction {
  font-weight: bold;
}

.SecondaryAction {
  font-weight: normal;
}

.SnackbarContentRoot {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.ActionsHidden {
  display: none;
}
