.Line {
  width: 2px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;

  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 10px;
  background-repeat: repeat-x;
}

.Handle {
  fill: #fff;
  stroke: #9e9e9e;
  transition: fill 0.3s;
  z-index: 3;

  &:hover {
    cursor: col-resize;
    fill: #0d47a1;
    transition: fill 0.3s;

    &:active {
      fill: #0d47a1;
      transition: fill 0.3s;
    }
  }

  &:active {
    fill: #0d47a1;
    transition: fill 0.3s;
  }
}
