div.grid {
  // grid
  height: 100%;

  div[aria-selected='true'] {
    outline: none;
  }

  //--rdg-border-color: none;
  & > div {
    // first row

    // Info header cell
    &:first-child {
      > div:first-child {
        padding-left: 12px;
      }
    }

    &:first-child > div {
      // divs of the first row
      //display: block;
      padding: 0;
    }

    &:not(:first-child) > div {
      // divs of all rows but the header
      display: flex;
      user-select: all;
    }

    & > div {
      //border-right:1px solid #ddd
    }
  }
}

div.gridContainer {
  overflow: hidden;
}
