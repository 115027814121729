.scale {
  //border-radius: 50%;
  border: solid 0.5px grey;
  //box-shadow: 0.5px 0.5px #888888;
  width: 24px;
  height: 24px;
}

.cell {
  height: 28px;
}
