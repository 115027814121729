.Root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.AbsoluteWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  user-select: none;

  & > * {
    pointer-events: all;
  }
}

.Content {
  position: relative;
  height: 100vh;
  width: 100vw;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}
