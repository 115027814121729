.AppBar {
  border-bottom: 1px solid #eeeeee;
}

.Toolbar {
  justify-content: space-between;
  min-height: 50px !important;
}

.ButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  padding: 4px 12px 4px;
  cursor: pointer;

  transition: all 240ms ease-in-out;
  &:hover {
    background-color: #eeeeee;
  }
}

button.button {
  height: 30px;
  border-radius: 15px;
  min-width: auto;
  &:hover {
    background-color: #eeeeee;
  }
}

p.buttonText {
  font-size: 14px;
  margin-left: 8px;
  white-space: nowrap;
}
