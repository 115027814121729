.ForeignObject {
  overflow: visible;
}

.Root {
  transform: translate(-100%, -100%) translate(-8px, -8px);
  z-index: 3000;
}

.Row {
  align-items: center;
  justify-content: space-between;
}
