@grey400: #bdbdbd;

.Box {
  pointer-events: none;

  button {
    pointer-events: all;
  }
}

.Paper {
  pointer-events: all;
  display: inline-block !important;
}

div.Disabled {
  background-color: @grey400;
  opacity: 0.95;
  cursor: not-allowed;
}
