@grey200: #eeeeee;

.Root {
  pointer-events: none;
  position: absolute;
}

div.Paper {
  pointer-events: all;
  overflow-y: visible;

  &.PaperClosed {
    transform: translateY(100%) !important;
  }
}

.ActionButtons {
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translate(0, -100%) translateY(-12px);
  align-items: center;

  &.Closed {
    transform: translateY(-100%) translateY(-24px);
    visibility: visible;
    transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1) 140ms !important;
  }

  button {
    margin: 0 4px;
    background-color: white;

    svg {
      background-color: transparent !important;
    }

    &:hover {
      background-color: @grey200;
    }
  }
}

.ActionButtonsLeft {
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate(12px, -100%) translateY(-12px);
  align-items: center;

  &.Closed {
    transform: translate(12px, -100%) translateY(-24px);
    visibility: visible;
    transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1) 140ms !important;
  }
}
