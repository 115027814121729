@red500: #f44336;

div.listCard {
  background-color: white;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  transition: all ease-in-out 2s;
}

div.top {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

div.titleTop {
  flex: 1;
  padding-left: 8px;
}

div.separator {
  height: 0.5px;
  width: 100%;
  opacity: 0.1;
  background-color: black;
}

div.separator2 {
  height: 1px;
  width: 100%;
  opacity: 0.1;
  background-color: black;
}

div.bottom {
  height: 80px;
  width: 100%;
}

div.header {
  height: 40px;
  display: grid;
  grid-template-columns: 2fr 2fr auto;
  gap: 12px;
  align-items: center;
  padding-right: 12px;

  .fieldName {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .cropType {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: end;
  }

  .headerEmptyMessage {
    color: @red500;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: right;
  }

  .sowingDate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

li.listItem {
  padding: 4px 36px;

  @media screen and (max-width: 800px) {
    padding: 4px 8px;
  }
}

table.tableRoot {
  flex: 1;
  flex-grow: 3;
}

.tableBody {
  overflow-y: auto;

  td.outsideSeasonHeader {
    padding-left: 8px;
  }
}

.tableBodyTop {
  overflow-y: auto;
  tr {
    td {
      border-bottom: 0;
    }
  }
}

td.tableRowEmpty {
  text-align: center;
  color: @red500;
}

th.headCell {
  padding-left: 8px;
  font-weight: 400;
  color: #aaa;
  width: 15%;
  text-align: left;
}

td.bodyCell1 {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
  max-width: 100px;
}

td.bodyCell {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
}

button.collapseButton {
  margin-right: 6px;
}

.checkbox {
  &:hover {
    background-color: transparent !important;
  }
}
