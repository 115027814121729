.Title {
  font-size: 16px;
  margin: 0 0 0 6px;
}

.ForeignObject {
  overflow: visible;
}

.Root {
  transform: translate(-12px, 0);
}

.TileImage {
  width: 24px;
  max-height: 24px;
  max-width: 24px;
}
