.Root {
  text-align: center;
  vertical-align: middle;
}

div.Content {
  &:first-child {
    padding: 0;
  }
}

.LogoWrapper {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: 24px;
}

.Message {
  margin-top: 24px;
  color: black;
  font-size: 18px;
}

/*==================
    Animations
==================*/

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
