@grey200: #eeeeee;

div.ContentRoot {
  &:first-child {
    padding: 0;
  }
}

div.ListItemText {
  border: 1px solid @grey200;
  border-radius: 4px;
}

.Icon {
  justify-content: flex-end;
}

.WeatherStationSelect {
  text-overflow: ellipsis;
  max-width: 200px;
}

.RightColumn {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
}

.Label {
  width: max-content;
}
