@grey500: #9e9e9e;
@grey800: #424242;

.root {
  cursor: pointer;
}

div.white,
svg.white {
  color: white;
}
div.black,
svg.black {
  color: black;
}

div.ListItemText {
  cursor: pointer;
  padding-right: 0;
  padding-left: 0;
}

.EditList {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

div.ListItem2 {
  cursor: pointer;
  padding: 4px 8px;
}

div.ListItemText {
  font-size: 0.8rem;
}

div.ListItemSecondaryAction {
  margin-right: -8px;

  button {
    padding: 8px;

    svg {
      font-size: 1.2rem;
    }
  }
}

div.Disabled,
svg.Disabled {
  opacity: 0.6 !important;
}

.PopoverContainer {
  padding: 12px;
  overflow-y: auto;
  max-height: 95vh;
  .narrow-scrollbar();
}

.AddNewButton {
  padding: 4px !important;
}

.List {
  //max-height: 300px;
}

div.Accordion {
  font-size: 0.9rem;
  margin: 0;
}

div.AccordionDetails {
  padding: 0;
  display: block;
}

div.AccordionSummaryRoot {
  padding: 0 2px 0 0;
}

.heading {
  flex-basis: 33.33%;
  flex-shrink: 0;
  color: @grey800;
}

.secondaryHeading {
  color: @grey500;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}

div.restoreButton {
  right: 0;
}
