.paper {
  pointer-events: all;
  background-color: white;
  border-radius: 4px !important;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.menuPaper {
  .paper();
  .narrow-scrollbar();

  min-width: 260px !important;
  padding-right: 8px;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
