@red100: #ffcdd2;

div.box {
  border-radius: 4px;
  border: 1px solid #000;
  box-sizing: border-box;
}

div.center,
button.center {
  width: 33% !important;
  height: 20px;
  background-color: white !important;
  border-radius: 10px;
}

button.center {
  &:hover {
    background-color: @red100 !important;
  }
}

p.text {
  max-width: 195px;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
