div.Paper {
  padding: 0 8px;
  max-width: 200px;
  width: 200px;

  .narrow-scrollbar();
}

.Text {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: none;
  text-align: center;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
