// Hover does not work for some reason.
.title {
  margin: 0;
  font-size: 0.9rem;
  padding-bottom: 8px;
}

.container:hover .group {
  opacity: 0.3;
}

.container .group:hover {
  opacity: 1;
}

.container .group:hover .name {
  font-weight: 400;
}

.container .group:hover .inside {
  font-size: 0.8em;
}

.container .group:hover .outside {
  font-size: 0.8em;
}
