@grey200: #eeeeee;

.Root {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  button {
    background-color: white;

    &:hover {
      background-color: @grey200;
    }
  }
}
