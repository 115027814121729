.root {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  overflow-x: auto;
  flex: 1 1 auto;
}

.emptyWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

ul.ListItemText {
  padding: 0;
}
