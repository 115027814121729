p.title {
  font-size: 12px;
  line-height: 1.2;
}

p.subtitle {
  font-size: 12px;
  line-height: 1.2;
  color: #a7a9ac;
}

button.iconButton {
  padding: 4px;
  height: 32px;
}

div.absolute {
  position: absolute;
  height: 100%;
  width: -moz-calc(100% - 16px);
  width: calc(100% - 16px);
}

div.shortcutWrapper {
  pointer-events: none;
  * {
    pointer-events: all;
  }
}
