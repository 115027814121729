@text-size: 1rem;
@grey200: #eeeeee;

.Root {
  user-select: none;
  position: relative;
  height: 75px;
  display: flex;
  flex-direction: column;

  & * {
    user-select: none;
  }

  canvas {
    border: 0.5px solid @grey200;
  }
}

.Spacer {
  height: 25px;
}

.Title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 25px;
  height: 25px;
  color: #757575;
  font-size: 14px;
  white-space: nowrap;
}

.LowestValue {
  padding: 4px;
  position: absolute;
  left: 4px;
  font-size: @text-size;
  height: 25px;
  top: 50px;
}

.HighestValue {
  padding: 4px;
  position: absolute;
  right: 4px;
  font-size: @text-size;
  height: 25px;
  top: 50px;
}

.Handle {
  background-color: black;
  width: 2px;
  height: 25px;
  position: absolute;
  top: 26px;
}

.Value {
  height: 25px;
  line-height: 25px;
  position: absolute;
  transform: translate(-50%, 0);
  top: 50px;
}

.DemandIcon {
  position: absolute;
  left: 50%;
  bottom: -22px;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}
