div.root {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
}

div.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.search {
  box-shadow: none;
  float: right;
  height: 35px;
}

div.buttonWrapper {
  display: flex;
  align-items: center;
}

button.menuButton {
  margin-left: -12px;
  margin-right: 30px;
  color: white;
}

div.container {
  height: calc(100% - 165px);
  overflow-y: auto;
  display: flex;
  background-color: #f9f9f9;
}

div.innerContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

div.loadingWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.filter {
  box-sizing: border-box;
  min-height: 110px;
  width: 100%;
  overflow: hidden;
  padding: 0 32px;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);

  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

label.formLabel {
  align-self: flex-end;
  padding-bottom: 12px;

  span {
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
  }
}

.checkbox {
  &:hover {
    background-color: transparent !important;
  }
}

.datePickerBox {
  padding-bottom: 12px;
  align-self: flex-start;
}

.datePickerLabel {
  opacity: 0.6;
}

.clickableTypography {
  cursor: pointer !important;
}

.unClickableTypography {
  cursor: default;
}
