.Root {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 56px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  &.Disabled {
    pointer-events: none !important;
    user-select: none !important;
  }
}

.GoogleMap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  &.MagicWand div {
    cursor: crosshair;
  }
}
