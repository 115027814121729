.PrescriptionDownloader_LoadingRoot__s969Q hr {
  display: none;
}
.PrescriptionDownloader_LoadingWrapper__1TRfa {
  padding: 50px;
  text-align: center;
}
.PrescriptionDownloader_GridList__2sOBU {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.PrescriptionDownloadTile_Tile__3xUiM {
  -webkit-user-select: none;
          user-select: none;
  pointer-events: all;
  margin: 8px;
  height: 160px;
  width: 260px;
}
.PrescriptionDownloadTile_Tile__3xUiM:hover .PrescriptionDownloadTile_TitleBar__34xjN {
  display: flex;
}
.PrescriptionDownloadTile_TileBar__G-kN- {
  -webkit-user-select: none;
          user-select: none;
  display: none;
}
.PrescriptionDownloadTile_TileImage__2dGn- {
  width: 75%;
  margin: auto;
  max-height: 75%;
  max-width: 75%;
}
.PrescriptionDownloadTile_Icon__3wno0 {
  color: white;
  margin-right: 12px;
  -webkit-user-select: none;
          user-select: none;
}
.PrescriptionDownloadTile_Paper__Dk-y9 {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 12px;
}
.PrescriptionDownloadTile_Paper__Dk-y9:hover {
  background-color: #c8e6c9;
}

.map-field-add-outline {
  pointer-events: none !important;
  padding: 12px;
  text-align: center;
  z-index: 8 !important;
}

.WeatherNetworkMarkersCanvas_Root__9jzic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.WeatherNetworkMarkersCanvas_CanvasWrapper__17x78 {
  position: relative;
  width: 100%;
  height: 100%;
}
.WeatherNetworkMarkersCanvas_Canvas__3H-xd {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.WeatherNetworkMarkersCanvas_Hidden__3F3kS {
  display: none;
}

.ToolbarContainer_root__2PJul {
  pointer-events: none;
  z-index: 2;
}
.ToolbarContainer_childContainer__2SQZS > * {
  pointer-events: all;
}

.GoogleMap_Root__3-CpD {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 56px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.GoogleMap_Root__3-CpD.GoogleMap_Disabled__1TSuH {
  pointer-events: none !important;
  -webkit-user-select: none !important;
          user-select: none !important;
}
.GoogleMap_GoogleMap__2yX8s {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.GoogleMap_GoogleMap__2yX8s.GoogleMap_MagicWand__1K8tx div {
  cursor: crosshair;
}

.ProgressLoadingModal_Root__1Z2yB {
  text-align: center;
  vertical-align: middle;
}
div.ProgressLoadingModal_Content__2QE4s:first-child {
  padding: 0;
}
.ProgressLoadingModal_LogoWrapper__3VJ1x {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-bottom: 24px;
}
.ProgressLoadingModal_Message__37wlm {
  margin-top: 24px;
  color: black;
  font-size: 18px;
}
/*==================
    Animations
==================*/
@keyframes ProgressLoadingModal_spin__3gDYG {
  100% {
    transform: rotate(360deg);
  }
}

.MapFloatPanel_Root__13JD6 {
  position: absolute;
  pointer-events: none;
}
.MapFloatPanel_Root__13JD6 > * {
  pointer-events: all;
}

.GraphTooltip_ForeignObject__3F4Pk {
  overflow: visible;
}
.GraphTooltip_Root__3tZRU {
  transform: translate(-100%, -100%) translate(-8px, -8px);
  z-index: 3000;
}
.GraphTooltip_Row__eLUWI {
  align-items: center;
  justify-content: space-between;
}

.GraphVerticalLimitLine_Title__2lbmz {
  font-size: 16px;
  margin: 0 0 0 6px;
}
.GraphVerticalLimitLine_ForeignObject__1Dyd3 {
  overflow: visible;
}
.GraphVerticalLimitLine_Root__2cV4x {
  transform: translate(-12px, 0);
}
.GraphVerticalLimitLine_TileImage__1ArnW {
  width: 24px;
  max-height: 24px;
  max-width: 24px;
}

.BottomDrawer_Root__3R8os {
  pointer-events: none;
  position: absolute;
}
div.BottomDrawer_Paper__1A5bk {
  pointer-events: all;
  overflow-y: visible;
}
div.BottomDrawer_Paper__1A5bk.BottomDrawer_PaperClosed__3M9wS {
  transform: translateY(100%) !important;
}
.BottomDrawer_ActionButtons__3IP8B {
  display: inline-flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translate(0, -100%) translateY(-12px);
  align-items: center;
}
.BottomDrawer_ActionButtons__3IP8B.BottomDrawer_Closed__1xvWf {
  transform: translateY(-100%) translateY(-24px);
  visibility: visible;
  transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1) 140ms !important;
}
.BottomDrawer_ActionButtons__3IP8B button {
  margin: 0 4px;
  background-color: white;
}
.BottomDrawer_ActionButtons__3IP8B button svg {
  background-color: transparent !important;
}
.BottomDrawer_ActionButtons__3IP8B button:hover {
  background-color: #eeeeee;
}
.BottomDrawer_ActionButtonsLeft__29p4Y {
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate(12px, -100%) translateY(-12px);
  align-items: center;
}
.BottomDrawer_ActionButtonsLeft__29p4Y.BottomDrawer_Closed__1xvWf {
  transform: translate(12px, -100%) translateY(-24px);
  visibility: visible;
  transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1) 140ms !important;
}

button.SurveyReport_closeButton__2_uMA {
  padding-right: 60px;
  padding-left: 60px;
}

.InfoSettingsButton_Root__2aT1Z {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.InfoSettingsButton_Root__2aT1Z button {
  background-color: white;
}
.InfoSettingsButton_Root__2aT1Z button:hover {
  background-color: #eeeeee;
}

.weather-graph {
  position: relative;
  height: 100%;
  width: 100%;
}
.weather-graph .notifications-empty-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.weather-graph .notifications-empty-notice svg {
  margin-left: 5px;
}

.DateRangeInput_Root__anzjs,
.DateRangeInput_Root__anzjs * {
  cursor: pointer !important;
}
input.DateRangeInput_Input__15ntj {
  padding: 20px 12px 12px;
}
.DateRangeInput_Year__xM4Qv,
.DateRangeInput_InputAdornment__1Fqo- {
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.DateRangeInput_Day__KB4q6 {
  width: 36px;
  height: 36px;
  margin: 0 2px;
}
.DateRangeInput_Day__KB4q6 span {
  font-size: 14px;
  font-weight: bold;
  color: inherit;
}
.DateRangeInput_Highlight__1d2LQ {
  background-color: #2962ff;
  opacity: 0.4;
}
.DateRangeInput_Highlight__1d2LQ .DateRangeInput_Day__KB4q6 {
  color: white;
}
.DateRangeInput_StartHighlight__3uLjm {
  background-color: #2962ff;
  opacity: 0.4;
  opacity: 1;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.DateRangeInput_StartHighlight__3uLjm .DateRangeInput_Day__KB4q6 {
  color: white;
}
.DateRangeInput_StartHighlight__3uLjm.DateRangeInput_SelectedStart__OXSrn {
  opacity: 0.4;
}
.DateRangeInput_EndHighlight__eYXTc {
  background-color: #2962ff;
  opacity: 0.4;
  opacity: 1;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.DateRangeInput_EndHighlight__eYXTc .DateRangeInput_Day__KB4q6 {
  color: white;
}
.DateRangeInput_EndHighlight__eYXTc.DateRangeInput_SelectedEnd__1exKU {
  opacity: 0.4;
}
.DateRangeInput_NonCurrentMonthDay__2Wsdw.DateRangeInput_Day__KB4q6 {
  color: #bdbdbd !important;
  font-weight: lighter;
}
.DateRangeInput_HighlightNonCurrentMonthDay__nKvBj.DateRangeInput_Day__KB4q6 {
  color: #616161;
}
div.DateRangeInput_PaperStart__3_Pb9 {
  overflow: hidden;
  border-radius: 0;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}
div.DateRangeInput_PaperEnd__3l-id {
  overflow: hidden;
  border-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.DateRangeInput_disabled__3zTA2 {
  pointer-events: none;
  opacity: 0.6;
}
.DateRangeInput_disabled__3zTA2 .DateRangeInput_input__jpCh9.DateRangeInput_Input__15ntj {
  color: #616161;
}

.MapCanvas_Loading__13uZl {
  background: repeating-linear-gradient(-45deg, #f5f5f5, #f5f5f5 20px, #bdbdbd 20px, #bdbdbd 40px);
  background-size: 56px 56px;
  /* This is unique for this background, need to find a pattern and develop a formula */
  animation: MapCanvas_slide__2ysdh 20s infinite linear forwards;
  opacity: 0.5;
}
.MapCanvas_Error__74Wr6 {
  background: repeating-linear-gradient(-45deg, #e57373, #e57373 20px, #bdbdbd 20px, #bdbdbd 40px);
  background-size: 56px 56px;
  /* This is unique for this background, need to find a pattern and develop a formula */
  opacity: 0.5;
}
@keyframes MapCanvas_slide__2ysdh {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.MapLegendContainer_paper__3K2_9 {
  pointer-events: all;
  background-color: white;
  border-radius: 4px !important;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}
.MapLegendContainer_menuPaper__3NsTn {
  pointer-events: all;
  background-color: white;
  border-radius: 4px !important;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  min-width: 260px !important;
  padding-right: 8px;
}
.MapLegendContainer_menuPaper__3NsTn::-webkit-scrollbar {
  width: 8px;
}
.MapLegendContainer_menuPaper__3NsTn::-webkit-scrollbar-track {
  background: #eeeeee;
}
.MapLegendContainer_menuPaper__3NsTn::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.MapLegendContainer_menuPaper__3NsTn::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
.MapLegendContainer_narrow-scrollbar__2BB4V {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.MapLegendContainer_narrow-scrollbar__2BB4V::-webkit-scrollbar {
  width: 8px;
}
.MapLegendContainer_narrow-scrollbar__2BB4V::-webkit-scrollbar-track {
  background: #eeeeee;
}
.MapLegendContainer_narrow-scrollbar__2BB4V::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.MapLegendContainer_narrow-scrollbar__2BB4V::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

li.SubList_sticky__3b0wV {
  background-color: white;
}
li.SubList_checkboxEnabled__10AzH {
  padding-left: 6px;
}

.LayerHeaderItem_tooltip__26x9v {
  font-size: 14px;
}
.LayerHeaderItem_switchRoot__1dWr7 {
  padding: 0 16px !important;
}

div.SelectSeasonFormControl_Paper__2nmjN {
  padding: 0 8px;
  max-width: 200px;
  width: 200px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
div.SelectSeasonFormControl_Paper__2nmjN::-webkit-scrollbar {
  width: 8px;
}
div.SelectSeasonFormControl_Paper__2nmjN::-webkit-scrollbar-track {
  background: #eeeeee;
}
div.SelectSeasonFormControl_Paper__2nmjN::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
div.SelectSeasonFormControl_Paper__2nmjN::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
.SelectSeasonFormControl_Text__EH1lg {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: none;
  text-align: center;
}
.SelectSeasonFormControl_narrow-scrollbar__2PN6Q {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.SelectSeasonFormControl_narrow-scrollbar__2PN6Q::-webkit-scrollbar {
  width: 8px;
}
.SelectSeasonFormControl_narrow-scrollbar__2PN6Q::-webkit-scrollbar-track {
  background: #eeeeee;
}
.SelectSeasonFormControl_narrow-scrollbar__2PN6Q::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.SelectSeasonFormControl_narrow-scrollbar__2PN6Q::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

.ActionToast_Root__2k2wP {
  transform: translate(-50%, 36px) !important;
  max-width: 400px;
}
.ActionToast_Root__2k2wP div {
  background-color: white;
  color: #37474f;
}
.ActionToast_PrimaryAction__xG5sV {
  font-weight: bold;
}
.ActionToast_SecondaryAction__38T6P {
  font-weight: normal;
}
.ActionToast_SnackbarContentRoot__1SvUm {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.ActionToast_ActionsHidden__2cy0V {
  display: none;
}

.CreateUpdateSeason_DialogActions__2mEl6 {
  justify-content: space-between !important;
}

.CreateUpdateSeasonDateInput_root__Kb5z5 {
  position: relative;
  margin-top: 16px;
}
.CreateUpdateSeasonDateInput_border__3amTW {
  position: relative;
  top: 0;
  border: 1.5px solid #212121;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.CreateUpdateSeasonDateInput_title__35Wvg {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1;
  top: 0;
  transform: translateY(-50%);
  left: 10px;
  background-color: white;
}
div.CreateUpdateSeasonDateInput_datePicker__cOrln,
.CreateUpdateSeasonDateInput_inputRoot__SY9Yr,
input.CreateUpdateSeasonDateInput_input__28uYH {
  cursor: pointer !important;
  width: 100%;
}
div.CreateUpdateSeasonDateInput_datePicker__cOrln fieldset,
.CreateUpdateSeasonDateInput_inputRoot__SY9Yr fieldset,
input.CreateUpdateSeasonDateInput_input__28uYH fieldset {
  border: hidden;
}
input.CreateUpdateSeasonDateInput_input__28uYH {
  padding: 8px;
}

.FieldTextCanvas_Canvas__1hf9S {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
}

div.ManageCrops_Root__91-XI {
  z-index: 10;
}
div.ManageCrops_SelectRoot__3LiI0 {
  display: inline-flex;
}
div.ManageCrops_ListItemText__tkPIa {
  display: inline-flex;
}
div.ManageCrops_ListItemIcon__2fPaS {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
li.ManageCrops_ListItemText__tkPIa {
  padding-left: 8px;
}
div.ManageCrops_ListItemSecondaryAction__TNRjv {
  right: 0;
}
.ManageCrops_List__397dy {
  max-height: 40vh;
  overflow: auto;
  margin-right: -16px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.ManageCrops_List__397dy::-webkit-scrollbar {
  width: 8px;
}
.ManageCrops_List__397dy::-webkit-scrollbar-track {
  background: #eeeeee;
}
.ManageCrops_List__397dy::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.ManageCrops_List__397dy::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
.ManageCrops_narrow-scrollbar__10zp4 {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.ManageCrops_narrow-scrollbar__10zp4::-webkit-scrollbar {
  width: 8px;
}
.ManageCrops_narrow-scrollbar__10zp4::-webkit-scrollbar-track {
  background: #eeeeee;
}
.ManageCrops_narrow-scrollbar__10zp4::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.ManageCrops_narrow-scrollbar__10zp4::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

.InlineDatePicker_Icon__3oYma {
  justify-content: flex-end;
}
.InlineDatePicker_CropSelect__2ef3z {
  text-overflow: ellipsis;
}
.InlineDatePicker_DatePicker__21_tc {
  width: 100%;
}
.InlineDatePicker_DatePicker__21_tc input,
.InlineDatePicker_DatePicker__21_tc button,
.InlineDatePicker_DatePicker__21_tc div {
  cursor: pointer;
}
.InlineDatePicker_DatePicker__21_tc button {
  padding: 0;
}
div.InlineDatePicker_InputError__fO0oo input {
  color: #d50000;
}
div.InlineDatePicker_InputError__fO0oo svg {
  fill: #d50000;
}

svg.SelectCrop_IconError__3MQu- {
  color: #d50000;
}
div.SelectCrop_SelectRoot__2obRu {
  display: inline-flex;
  height: 1.1875em;
}
div.SelectCrop_SelectRootError__3n68q label {
  color: #d50000;
}
div.SelectCrop_ListItemText__3Btth {
  display: inline-flex;
}
div.SelectCrop_ListItemIcon__3xqUu {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
div.SelectCrop_ListItemText__3Btth {
  margin-bottom: 0;
  margin-top: 0;
}

.ErrorSnackbar_Error__2x4Ov div {
  background-color: #d50000 !important;
}
.ErrorSnackbar_ErrorAction__3Sfe6 {
  background-color: white !important;
}
.ErrorSnackbar_ErrorAction__3Sfe6 span {
  color: #d50000;
}

.SurveyStatisticsContainer_paper__2n_TZ {
  pointer-events: all;
  background-color: white;
  border-radius: 4px !important;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}

.SurveyStatisticsBarGroup_group__1RxD6 {
  margin: 4px 0;
  width: 100%;
}
.SurveyStatisticsBarGroup_bar__3V5ko {
  height: 100%;
}
.SurveyStatisticsBarGroup_name__1VHJD {
  color: black;
  font-weight: 300;
  font-size: 1em;
  margin: 0 10px 0 0;
}
.SurveyStatisticsBarGroup_inside__2uw49 {
  color: white;
  cursor: default;
  font-weight: 900;
  font-size: 0.7em;
  margin: 0 5px 0 0;
}
.SurveyStatisticsBarGroup_outside__UB7ce {
  color: black;
  cursor: default;
  font-weight: 900;
  font-size: 0.7em;
  margin: 0 0 0 5px;
}

.SurveyStatisticsBarChart_title__3altX {
  margin: 0;
  font-size: 0.9rem;
  padding-bottom: 8px;
}
.SurveyStatisticsBarChart_container__2FH_2:hover .SurveyStatisticsBarChart_group__3Ma1p {
  opacity: 0.3;
}
.SurveyStatisticsBarChart_container__2FH_2 .SurveyStatisticsBarChart_group__3Ma1p:hover {
  opacity: 1;
}
.SurveyStatisticsBarChart_container__2FH_2 .SurveyStatisticsBarChart_group__3Ma1p:hover .SurveyStatisticsBarChart_name__2ngGa {
  font-weight: 400;
}
.SurveyStatisticsBarChart_container__2FH_2 .SurveyStatisticsBarChart_group__3Ma1p:hover .SurveyStatisticsBarChart_inside__COHC9 {
  font-size: 0.8em;
}
.SurveyStatisticsBarChart_container__2FH_2 .SurveyStatisticsBarChart_group__3Ma1p:hover .SurveyStatisticsBarChart_outside__2J5eb {
  font-size: 0.8em;
}

.SurveyStatisticsUtils_scale__18rBC {
  border: solid 0.5px grey;
  width: 24px;
  height: 24px;
}
.SurveyStatisticsUtils_cell__3pTe7 {
  height: 28px;
}

div.SurveyStatistics_ButtonGroup__1-HO4 {
  display: block;
}

.MenuPopoverButton_box__JPULv {
  min-width: 180px;
  max-width: 180px;
  height: 56px;
}
div.MenuPopoverButton_paper__2IN_I {
  pointer-events: all;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}
p.MenuPopoverButton_text__1zzfl {
  padding: 0 8px;
  text-transform: none;
  font-weight: 500;
}
button.MenuPopoverButton_button__2sENm {
  width: 100%;
  height: 48px;
}
div.MenuPopoverButton_popover__VtWeI {
  pointer-events: none;
}

.FieldOverview_box__26_qp {
  min-width: 300px;
  max-width: 300px;
  position: relative;
}
div.FieldOverview_buttonRow__2sWMd {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px -2px 10px;
}
button.FieldOverview_button__17_wP {
  padding: 0;
  min-width: 0;
  height: 100%;
}
p.FieldOverview_buttonText__12w7r {
  font-size: 14px;
  font-weight: 300;
}
.FieldOverview_list__1A4UF {
  max-height: 50vh;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.FieldOverview_list__1A4UF::-webkit-scrollbar {
  width: 8px;
}
.FieldOverview_list__1A4UF::-webkit-scrollbar-track {
  background: #eeeeee;
}
.FieldOverview_list__1A4UF::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.FieldOverview_list__1A4UF::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
.FieldOverview_list__1A4UF ul {
  padding: 0;
}
li.FieldOverview_subheader__v_B0w {
  line-height: 28px;
}
.FieldOverview_narrow-scrollbar__2Moho {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.FieldOverview_narrow-scrollbar__2Moho::-webkit-scrollbar {
  width: 8px;
}
.FieldOverview_narrow-scrollbar__2Moho::-webkit-scrollbar-track {
  background: #eeeeee;
}
.FieldOverview_narrow-scrollbar__2Moho::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.FieldOverview_narrow-scrollbar__2Moho::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

p.FieldOverviewListSection_text__3TW-L {
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
}
.FieldOverviewListSection_checkbox__3jT3L {
  padding: 0 !important;
  display: block;
}

div.FieldOverviewListItem_imageHolder__2CKa6 {
  background-color: #00000029;
  border-radius: 8px;
}
p.FieldOverviewListItem_text__XdHQG {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}
.FieldOverviewListItem_checkbox__3qA9x {
  padding: 0 !important;
  display: block;
}
li.FieldOverviewListItem_item__1GO8i {
  padding-top: 4px;
  padding-bottom: 4px;
}

.MenuButton_box__icBmP {
  min-width: 180px;
  max-width: 180px;
  height: 56px;
}
div.MenuButton_paper__EFfZJ {
  pointer-events: all;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}
p.MenuButton_text__2zdka {
  padding: 0 8px;
  text-transform: none;
  font-weight: 500;
}
button.MenuButton_button__20cGO {
  width: 100%;
  height: 48px;
}

div.FieldSettingsDialog_ContentRoot__3w5kb:first-child {
  padding: 0;
}
div.FieldSettingsDialog_ListItemText__2jMfW {
  border: 1px solid #eeeeee;
  border-radius: 4px;
}
.FieldSettingsDialog_Icon__3ADt0 {
  justify-content: flex-end;
}
.FieldSettingsDialog_WeatherStationSelect__3ZjkK {
  text-overflow: ellipsis;
  max-width: 200px;
}
.FieldSettingsDialog_RightColumn__2EOjA {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
}
.FieldSettingsDialog_Label__VjlTt {
  width: max-content;
}

div.SelectFarmMenu_Paper__Cw_8I {
  transform: translateX(-12px) translateY(24px) !important;
  width: 300px;
}
div.SelectFarmMenu_Search__1NCWj {
  box-shadow: none;
  height: 35px;
  padding: 8px;
}
.SelectFarmMenu_narrow-scrollbar__nWF6J {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.SelectFarmMenu_narrow-scrollbar__nWF6J::-webkit-scrollbar {
  width: 8px;
}
.SelectFarmMenu_narrow-scrollbar__nWF6J::-webkit-scrollbar-track {
  background: #eeeeee;
}
.SelectFarmMenu_narrow-scrollbar__nWF6J::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.SelectFarmMenu_narrow-scrollbar__nWF6J::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

div.SelectFarmDialog_DialogContent__8hBly {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
div.SelectFarmDialog_DialogContent__8hBly::-webkit-scrollbar {
  width: 8px;
}
div.SelectFarmDialog_DialogContent__8hBly::-webkit-scrollbar-track {
  background: #eeeeee;
}
div.SelectFarmDialog_DialogContent__8hBly::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
div.SelectFarmDialog_DialogContent__8hBly::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
.SelectFarmDialog_narrow-scrollbar__1o99q {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.SelectFarmDialog_narrow-scrollbar__1o99q::-webkit-scrollbar {
  width: 8px;
}
.SelectFarmDialog_narrow-scrollbar__1o99q::-webkit-scrollbar-track {
  background: #eeeeee;
}
.SelectFarmDialog_narrow-scrollbar__1o99q::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.SelectFarmDialog_narrow-scrollbar__1o99q::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

p.DataIntegrationDialogContent_title__3yPKJ {
  font-size: 20px;
  font-weight: bold;
}
p.DataIntegrationDialogContent_itemTitle__X3FHd {
  font-size: 20px;
  font-weight: 300;
}
p.DataIntegrationDialogContent_content__36Y6J {
  font-size: 14px;
}
p.DataIntegrationDialogContent_italic__2Li0q {
  font-size: 14px;
  font-style: italic;
}
p.DataIntegrationDialogContent_itemSubtitle__3bqXf {
  color: #78a22f;
  font-size: 12px;
}
button.DataIntegrationDialogContent_cancel__3XD7t {
  font-size: 14px;
  font-weight: 400;
}
button.DataIntegrationDialogContent_delete__3_wKn {
  display: inline-block;
  padding: 0;
  min-height: 0;
  min-width: 0;
  width: 24px;
}

p.DataDisconnectDialogContent_title__2Awmo {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
p.DataDisconnectDialogContent_itemTitle__3wdrT {
  font-size: 20px;
  font-weight: 300;
}
p.DataDisconnectDialogContent_content__1djVi {
  font-size: 14px;
}
p.DataDisconnectDialogContent_itemSubtitle__3q822 {
  color: #78a22f;
  font-size: 12px;
}
button.DataDisconnectDialogContent_button__3Xkqc {
  font-size: 14px;
  font-weight: 400;
}
button.DataDisconnectDialogContent_delete__1Ux6y {
  display: inline-block;
  padding: 0;
  min-height: 0;
  min-width: 0;
  width: 24px;
}

.AppSettingsDialog_root__2-Ijf button:not(:first-child) {
  margin-left: 8px;
}

.SeasonSelector_root__3SG0t {
  cursor: pointer;
}
div.SeasonSelector_white__3ErdB,
svg.SeasonSelector_white__3ErdB {
  color: white;
}
div.SeasonSelector_black__2BjPi,
svg.SeasonSelector_black__2BjPi {
  color: black;
}
div.SeasonSelector_ListItemText__2B1x4 {
  cursor: pointer;
  padding-right: 0;
  padding-left: 0;
}
.SeasonSelector_EditList__3NMcx {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
div.SeasonSelector_ListItem2__Sx-Bg {
  cursor: pointer;
  padding: 4px 8px;
}
div.SeasonSelector_ListItemText__2B1x4 {
  font-size: 0.8rem;
}
div.SeasonSelector_ListItemSecondaryAction__3QrnM {
  margin-right: -8px;
}
div.SeasonSelector_ListItemSecondaryAction__3QrnM button {
  padding: 8px;
}
div.SeasonSelector_ListItemSecondaryAction__3QrnM button svg {
  font-size: 1.2rem;
}
div.SeasonSelector_Disabled__3sDRn,
svg.SeasonSelector_Disabled__3sDRn {
  opacity: 0.6 !important;
}
.SeasonSelector_PopoverContainer__3RHpi {
  padding: 12px;
  overflow-y: auto;
  max-height: 95vh;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.SeasonSelector_PopoverContainer__3RHpi::-webkit-scrollbar {
  width: 8px;
}
.SeasonSelector_PopoverContainer__3RHpi::-webkit-scrollbar-track {
  background: #eeeeee;
}
.SeasonSelector_PopoverContainer__3RHpi::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.SeasonSelector_PopoverContainer__3RHpi::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
.SeasonSelector_AddNewButton__3Vkg1 {
  padding: 4px !important;
}
div.SeasonSelector_Accordion__4fbAy {
  font-size: 0.9rem;
  margin: 0;
}
div.SeasonSelector_AccordionDetails__3eK89 {
  padding: 0;
  display: block;
}
div.SeasonSelector_AccordionSummaryRoot__3TO4L {
  padding: 0 2px 0 0;
}
.SeasonSelector_heading__cI_6x {
  flex-basis: 33.33%;
  flex-shrink: 0;
  color: #424242;
}
.SeasonSelector_secondaryHeading__18C42 {
  color: #9e9e9e;
}
.SeasonSelector_narrow-scrollbar__3Ux1z {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.SeasonSelector_narrow-scrollbar__3Ux1z::-webkit-scrollbar {
  width: 8px;
}
.SeasonSelector_narrow-scrollbar__3Ux1z::-webkit-scrollbar-track {
  background: #eeeeee;
}
.SeasonSelector_narrow-scrollbar__3Ux1z::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.SeasonSelector_narrow-scrollbar__3Ux1z::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
div.SeasonSelector_restoreButton__22tZb {
  right: 0;
}

/*==================
    Color Palette
==================*/
.LoginDialog_Title__2Ozj6 {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.LoginDialog_Name__3QqoJ {
  color: #78a22f;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  padding-left: 8px;
}
.LoginDialog_Slider__rJyZS {
  overflow: hidden !important;
  padding: 0 24px 24px;
  box-sizing: border-box;
}
.LoginDialog_Slide__2Pdle {
  height: 100%;
  padding: 0 !important;
}
div.LoginDialog_Content__3xtxp {
  padding: 0;
}
.LoginDialog_Language__QylNr {
  position: absolute;
  top: 24px;
  transform: translateY(-50%);
  right: 24px;
}
.LoginDialog_FloatingBox__1yVxu {
  position: absolute;
  top: -24px;
  transform: translateY(-100%);
  left: 0;
  background-color: white;
  padding: 12px;
  box-sizing: border-box;
}
div.LoginDialog_dialogPaper__pl4SE {
  overflow-y: visible;
}

.AppContainer_Root__3Vptn {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.AppContainer_AbsoluteWrapper__1E9fI {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}
.AppContainer_AbsoluteWrapper__1E9fI > * {
  pointer-events: all;
}
.AppContainer_Content__3Jp9D {
  position: relative;
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}
.AppContainer_Content__3Jp9D > * {
  pointer-events: all;
}

.FullScreenPortalPaper_Root__2F0sL {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: #eeeeee;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  box-sizing: border-box;
}
.FullScreenPortalPaper_Paper__nhnnt {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.ManageWeatherStationFieldConnections_Paper__2VNNL {
  max-width: 300px;
}

.DraggableIntervals_Root__18R8L {
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  flex: 1 1 auto;
}
.DraggableIntervals_Root__18R8L * {
  box-sizing: border-box;
}
.DraggableIntervals_Editor__3EZlh {
  box-sizing: border-box;
}
.DraggableIntervals_CanvasHolder__1PCet {
  position: relative !important;
  display: flex;
  margin-bottom: 30px;
  background-color: #eeeeee;
  transition: height 0.1s ease-out;
}

.DragHandle_Root__2Ntlp {
  background-color: white;
  border: 1px solid #9e9e9e;
  border-radius: 1px;
  z-index: 2;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  position: absolute;
  box-sizing: content-box;
}
.DragHandle_Root__2Ntlp.DragHandle_Vertical__3zJiT {
  transform: translate(-50%, -50%);
}
.DragHandle_Root__2Ntlp.DragHandle_Vertical__3zJiT button {
  cursor: row-resize;
}
.DragHandle_Root__2Ntlp.DragHandle_Horizontal__2p_w8 {
  transform: translate(-50%, -50%);
}
.DragHandle_Root__2Ntlp.DragHandle_Horizontal__2p_w8 button {
  cursor: col-resize;
}
.DragHandle_Root__2Ntlp:hover {
  background-color: #eeeeee;
}
.DragHandle_Root__2Ntlp:active {
  background-color: #eeeeee;
}
.DragHandle_RelativeWrapper__BtsD4 {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.DragHandle_RelativeWrapper__BtsD4 button {
  width: 100%;
  height: 100%;
  pointer-events: all;
}

.VerticalHandle_Line___uycj {
  width: 2px;
  height: 100%;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 10px;
  background-repeat: repeat-x;
}
.VerticalHandle_Handle__2yiUp {
  fill: #fff;
  stroke: #9e9e9e;
  transition: fill 0.3s;
  z-index: 3;
}
.VerticalHandle_Handle__2yiUp:hover {
  cursor: col-resize;
  fill: #0d47a1;
  transition: fill 0.3s;
}
.VerticalHandle_Handle__2yiUp:hover:active {
  fill: #0d47a1;
  transition: fill 0.3s;
}
.VerticalHandle_Handle__2yiUp:active {
  fill: #0d47a1;
  transition: fill 0.3s;
}

.CenterLine_Root__kse0t {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  pointer-events: none;
  height: 1px;
  transform: translateY(-50%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.IntervalVisualizer_SplitLine__33cQh {
  position: absolute;
  border-style: dashed;
  border-left: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.PrescriptionScale_Root__3kdSG {
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  height: 75px;
  display: flex;
  flex-direction: column;
}
.PrescriptionScale_Root__3kdSG * {
  -webkit-user-select: none;
          user-select: none;
}
.PrescriptionScale_Root__3kdSG canvas {
  border: 0.5px solid #eeeeee;
}
.PrescriptionScale_Spacer__2WXtI {
  height: 25px;
}
.PrescriptionScale_Title__3v6df {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 25px;
  height: 25px;
  color: #757575;
  font-size: 14px;
  white-space: nowrap;
}
.PrescriptionScale_LowestValue__1Wsw7 {
  padding: 4px;
  position: absolute;
  left: 4px;
  font-size: 1rem;
  height: 25px;
  top: 50px;
}
.PrescriptionScale_HighestValue__13O3c {
  padding: 4px;
  position: absolute;
  right: 4px;
  font-size: 1rem;
  height: 25px;
  top: 50px;
}
.PrescriptionScale_Handle__2eEmj {
  background-color: black;
  width: 2px;
  height: 25px;
  position: absolute;
  top: 26px;
}
.PrescriptionScale_Value__2sCB2 {
  height: 25px;
  line-height: 25px;
  position: absolute;
  transform: translate(-50%, 0);
  top: 50px;
}
.PrescriptionScale_DemandIcon__Jw3wk {
  position: absolute;
  left: 50%;
  bottom: -22px;
  transform: translateX(-50%);
  display: inline-flex;
  align-items: center;
  flex-direction: row;
}

.InputFieldUpdatedOnBlur_Root__3EJ1E {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.InputFieldUpdatedOnBlur_Root__3EJ1E::-webkit-outer-spin-button,
.InputFieldUpdatedOnBlur_Root__3EJ1E::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.ValueDisplayBoxes_Wrapper__2xGoP {
  bottom: -4px;
  transform: translate(0%, 100%);
  font-size: 14px;
  position: absolute;
  padding: 0;
  text-align: center;
  width: 120px;
  height: 28px;
  cursor: default;
}
.ValueDisplayBoxes_Wrapper__2xGoP > input {
  padding: 0 5px;
  transform: translate(-50%, 0);
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 1px;
  margin: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  font-weight: bold;
  cursor: text;
}
.ValueDisplayBoxes_Unit__1phwL {
  position: absolute;
  box-sizing: border-box;
  top: 8px;
  font-size: 12px;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  color: #9e9e9e;
  left: 8px;
}

.PrescriptionSplitView_Root__1U8LE {
  position: relative;
  height: 100%;
  width: 100%;
}
.PrescriptionSplitView_Map__1UAZx {
  position: relative;
  height: auto;
  width: 100%;
  flex: 1 1 auto;
  box-sizing: border-box;
}
.PrescriptionSplitView_Svg__3GtmJ {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  pointer-events: none;
}
.PrescriptionSplitView_Handle__2pE40 {
  z-index: 5000;
  position: absolute;
  background-color: white;
  border-radius: 1px;
  transform: translate(-50%, -50%);
}
.PrescriptionSplitView_Handle__2pE40 > button {
  pointer-events: all;
  cursor: ew-resize;
  width: 100%;
  height: 100%;
}

.PrescriptionOverrideList_Paper__1VPu- {
  pointer-events: all;
}

.MapCanvasMiniature_Root__2_F1b {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
  direction: rtl;
  border-radius: 4px !important;
}
.MapCanvasMiniature_Root__2_F1b::-webkit-scrollbar {
  width: 8px;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_Root__2_F1b::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_Root__2_F1b::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_Root__2_F1b::-webkit-scrollbar-thumb:hover {
  background: #757575;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_Root__2_F1b * {
  direction: ltr;
}
.MapCanvasMiniature_Paper__1uEpR {
  pointer-events: all;
  background-color: white;
  border-radius: 0 !important;
  overflow: hidden;
  box-sizing: border-box;
}
.MapCanvasMiniature_CloseButton__2ZQ7P {
  padding-top: 0;
  padding-bottom: 0;
}
.MapCanvasMiniature_narrow-scrollbar__3jsC8 {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.MapCanvasMiniature_narrow-scrollbar__3jsC8::-webkit-scrollbar {
  width: 8px;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_narrow-scrollbar__3jsC8::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_narrow-scrollbar__3jsC8::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_narrow-scrollbar__3jsC8::-webkit-scrollbar-thumb:hover {
  background: #757575;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.MapCanvasMiniature_Loading__17T69 {
  background: repeating-linear-gradient(-45deg, #f5f5f5, #f5f5f5 20px, #bdbdbd 20px, #bdbdbd 40px);
  background-size: 56px 56px;
  /* This is unique for this background, need to find a pattern and develop a formula */
  animation: MapCanvasMiniature_slide__EEIwr 20s infinite linear forwards;
  opacity: 0.5;
}
.MapCanvasMiniature_Error__1MGJN {
  background: repeating-linear-gradient(-45deg, #e57373, #e57373 20px, #bdbdbd 20px, #bdbdbd 40px);
  background-size: 56px 56px;
  /* This is unique for this background, need to find a pattern and develop a formula */
  opacity: 0.5;
}
@keyframes MapCanvasMiniature_slide__EEIwr {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.HeightMapToggle_Box__1N3YE {
  pointer-events: none;
}
.HeightMapToggle_Box__1N3YE button {
  pointer-events: all;
}
.HeightMapToggle_Paper__1Xz-B {
  pointer-events: all;
  display: inline-block !important;
}
div.HeightMapToggle_Disabled__1BNKL {
  background-color: #bdbdbd;
  opacity: 0.95;
  cursor: not-allowed;
}

.PrescriptionClassificationChangeDialog_Icon__2KIkR {
  align-items: center;
}

.PrescriptionEditor_AppBar__2yLOj {
  border-bottom: 1px solid #eeeeee;
}
.PrescriptionEditor_TextFieldBox__3UPWq {
  display: flex;
  flex-direction: row;
}
.PrescriptionEditor_Title__2OvHO {
  position: absolute;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%);
}
button.PrescriptionEditor_Button__3v16I {
  width: 100%;
  height: 40px;
  margin-top: 8px;
  margin-bottom: 4px;
  white-space: nowrap;
}
.PrescriptionEditor_ButtonWrapper__153Ik {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.PrescriptionEditor_Toolbar__3YvBN {
  justify-content: space-between;
  min-height: unset !important;
}

div.CreateBufferDialog_Root__3EzN3 {
  overflow: unset;
}

div.PrescriptionArchive_root__25RSa {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: white;
  overflow: hidden;
  width: 100%;
}
div.PrescriptionArchive_toolbar__2Wwo7 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div.PrescriptionArchive_search__zOtfv {
  box-shadow: none;
  float: right;
  height: 35px;
}
div.PrescriptionArchive_buttonWrapper__3BZsZ {
  display: flex;
  align-items: center;
}
button.PrescriptionArchive_menuButton__2XBy1 {
  margin-left: -12px;
  margin-right: 30px;
  color: white;
}
div.PrescriptionArchive_container__1nHw0 {
  height: calc(100% - 165px);
  overflow-y: auto;
  display: flex;
  background-color: #f9f9f9;
}
div.PrescriptionArchive_innerContainer__MH7lo {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
div.PrescriptionArchive_loadingWrapper__1mgSW {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.PrescriptionArchive_filter__2uX8i {
  box-sizing: border-box;
  min-height: 110px;
  width: 100%;
  overflow: hidden;
  padding: 0 32px;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
label.PrescriptionArchive_formLabel__2QNGc {
  align-self: flex-end;
  padding-bottom: 12px;
}
label.PrescriptionArchive_formLabel__2QNGc span {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
.PrescriptionArchive_checkbox__5GBWe:hover {
  background-color: transparent !important;
}
.PrescriptionArchive_datePickerBox__1TAFz {
  padding-bottom: 12px;
  align-self: flex-start;
}
.PrescriptionArchive_datePickerLabel__2Ucom {
  opacity: 0.6;
}
.PrescriptionArchive_clickableTypography__2BwL6 {
  cursor: pointer !important;
}
.PrescriptionArchive_unClickableTypography__1yZmp {
  cursor: default;
}

div.PrescriptionArchiveSelector_white__1Pbfn,
svg.PrescriptionArchiveSelector_white__1Pbfn {
  color: white;
}
div.PrescriptionArchiveSelector_black__2h1wY,
svg.PrescriptionArchiveSelector_black__2h1wY {
  color: black;
}
div.PrescriptionArchiveSelector_root__2MvJd {
  padding-top: 6px;
}
.PrescriptionArchiveSelector_title__1V8v4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
button.PrescriptionArchiveSelector_button__3zIId {
  height: 12px;
  font-size: 8px;
}
div.PrescriptionArchiveSelector_selector__3VyRV {
  cursor: pointer !important;
  padding-right: 36px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 400;
}
div.PrescriptionArchiveSelector_select__1q5p1 {
  padding-right: 36px;
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: 0.5;
  font-weight: 400;
}
div.PrescriptionArchiveSelector_disabled__3pFa9,
svg.PrescriptionArchiveSelector_disabled__3pFa9 {
  opacity: 0.6 !important;
}
.PrescriptionArchiveSelector_checkbox__3LOTl:hover {
  background-color: transparent !important;
}
label.PrescriptionArchiveSelector_formLabel__1HzkV {
  margin-left: 6px;
  margin-right: 6px;
}

div.PrescriptionTableToolbar_titleContainer__1IkIp {
  padding: 0px 12px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-end;
}
div.PrescriptionTableToolbar_titleContainer__1IkIp h6 {
  margin-right: 24px;
}
@media screen and (max-width: 800px) {
  div.PrescriptionTableToolbar_titleContainer__1IkIp {
    padding: 4px 8px;
  }
}
div.PrescriptionTableToolbar_filterContainer__2b4p8 {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 3px;
}
div.PrescriptionTableToolbar_filterContainer__2b4p8 p {
  margin-right: 24px;
}
.PrescriptionTableToolbar_italicTypography__1GS29 {
  font-style: italic;
}
.PrescriptionTableToolbar_clickableTypography__34UDA {
  cursor: pointer !important;
}

.PrescriptionArchiveTable_root__3mn0R {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.PrescriptionArchiveTable_tableWrapper__2Nc-L {
  overflow-x: auto;
  flex: 1 1 auto;
}
.PrescriptionArchiveTable_emptyWrapper__3h8ba {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
ul.PrescriptionArchiveTable_ListItemText__TCWgc {
  padding: 0;
}

div.PrescriptionArchiveExpandableListItem_listCard__1Pmu3 {
  background-color: white;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  transition: all ease-in-out 2s;
}
div.PrescriptionArchiveExpandableListItem_top__12IfN {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
div.PrescriptionArchiveExpandableListItem_titleTop__2aMFs {
  flex: 1 1;
  padding-left: 8px;
}
div.PrescriptionArchiveExpandableListItem_separator__2R2U2 {
  height: 0.5px;
  width: 100%;
  opacity: 0.1;
  background-color: black;
}
div.PrescriptionArchiveExpandableListItem_separator2__3tJgv {
  height: 1px;
  width: 100%;
  opacity: 0.1;
  background-color: black;
}
div.PrescriptionArchiveExpandableListItem_bottom__30xpm {
  height: 80px;
  width: 100%;
}
div.PrescriptionArchiveExpandableListItem_header__zwusl {
  height: 40px;
  display: grid;
  grid-template-columns: 2fr 2fr auto;
  grid-gap: 12px;
  gap: 12px;
  align-items: center;
  padding-right: 12px;
}
div.PrescriptionArchiveExpandableListItem_header__zwusl .PrescriptionArchiveExpandableListItem_fieldName__24SMu {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
div.PrescriptionArchiveExpandableListItem_header__zwusl .PrescriptionArchiveExpandableListItem_cropType__3zl6o {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: end;
}
div.PrescriptionArchiveExpandableListItem_header__zwusl .PrescriptionArchiveExpandableListItem_headerEmptyMessage__15HTN {
  color: #f44336;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
}
div.PrescriptionArchiveExpandableListItem_header__zwusl .PrescriptionArchiveExpandableListItem_sowingDate__HXQYj {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
li.PrescriptionArchiveExpandableListItem_listItem__1E8Fk {
  padding: 4px 36px;
}
@media screen and (max-width: 800px) {
  li.PrescriptionArchiveExpandableListItem_listItem__1E8Fk {
    padding: 4px 8px;
  }
}
table.PrescriptionArchiveExpandableListItem_tableRoot__1X3nA {
  flex: 1 1;
  flex-grow: 3;
}
.PrescriptionArchiveExpandableListItem_tableBody__3aiw3 {
  overflow-y: auto;
}
.PrescriptionArchiveExpandableListItem_tableBody__3aiw3 td.PrescriptionArchiveExpandableListItem_outsideSeasonHeader__1E4hj {
  padding-left: 8px;
}
.PrescriptionArchiveExpandableListItem_tableBodyTop__3V4rK {
  overflow-y: auto;
}
.PrescriptionArchiveExpandableListItem_tableBodyTop__3V4rK tr td {
  border-bottom: 0;
}
td.PrescriptionArchiveExpandableListItem_tableRowEmpty__1BIJG {
  text-align: center;
  color: #f44336;
}
th.PrescriptionArchiveExpandableListItem_headCell__38F6p {
  padding-left: 8px;
  font-weight: 400;
  color: #aaa;
  width: 15%;
  text-align: left;
}
td.PrescriptionArchiveExpandableListItem_bodyCell1__3Osni {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
  max-width: 100px;
}
td.PrescriptionArchiveExpandableListItem_bodyCell__rpO4l {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
}
button.PrescriptionArchiveExpandableListItem_collapseButton__ZOmzm {
  margin-right: 6px;
}
.PrescriptionArchiveExpandableListItem_checkbox__360Vn:hover {
  background-color: transparent !important;
}

td.PrescriptionTableRow_bodyCell1__2MYSW {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
  width: 15%;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
td.PrescriptionTableRow_bodyCell__2w0o2 {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
  width: 15%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
button.PrescriptionTableRow_action__3lWmR {
  padding: 4px;
  margin-right: 6px;
}
.PrescriptionTableRow_checkbox__2MhAO:hover {
  background-color: transparent !important;
}

div.PrescriptionDocumentation_Paper__1OM6S {
  padding: 0 12px 0 12px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
div.PrescriptionDocumentation_Paper__1OM6S::-webkit-scrollbar {
  width: 8px;
}
div.PrescriptionDocumentation_Paper__1OM6S::-webkit-scrollbar-track {
  background: #eeeeee;
}
div.PrescriptionDocumentation_Paper__1OM6S::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
div.PrescriptionDocumentation_Paper__1OM6S::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
ul.PrescriptionDocumentation_ListRoot__2nRLn {
  box-sizing: border-box;
}
div.PrescriptionDocumentation_ButtonRoot__tXtLD {
  display: flex;
  margin-right: 8px;
}
li.PrescriptionDocumentation_Subheader__2O-4M {
  padding-left: 0;
  padding-right: 6px;
}
li.PrescriptionDocumentation_Divider__szWHC {
  margin: 0 -12px;
}
label.PrescriptionDocumentation_LabelRoot__22Lxg {
  justify-content: space-between;
  width: 100%;
  margin-left: 0;
}
div.PrescriptionDocumentation_UploadPaper__14PFS {
  margin-bottom: 12px;
}
div.PrescriptionDocumentation_ListItemRoot__2rbX7 {
  width: calc(100% - 0px);
}
div.PrescriptionDocumentation_ListItemText__-yXHZ > * {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
div.PrescriptionDocumentation_ListItemSecondary__1UYnb {
  display: flex;
  align-content: center;
  align-items: center;
  pointer-events: none;
}
.PrescriptionDocumentation_narrow-scrollbar__3__Xk {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.PrescriptionDocumentation_narrow-scrollbar__3__Xk::-webkit-scrollbar {
  width: 8px;
}
.PrescriptionDocumentation_narrow-scrollbar__3__Xk::-webkit-scrollbar-track {
  background: #eeeeee;
}
.PrescriptionDocumentation_narrow-scrollbar__3__Xk::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.PrescriptionDocumentation_narrow-scrollbar__3__Xk::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

.FieldStatisticsToolbar_AppBar__2UiUy {
  border-bottom: 1px solid #eeeeee;
}
.FieldStatisticsToolbar_Toolbar__11BfA {
  justify-content: space-between;
  min-height: 50px !important;
}
.FieldStatisticsToolbar_ButtonWrapper__2Bq6_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  padding: 4px 12px 4px;
  cursor: pointer;
  transition: all 240ms ease-in-out;
}
.FieldStatisticsToolbar_ButtonWrapper__2Bq6_:hover {
  background-color: #eeeeee;
}
button.FieldStatisticsToolbar_button__1TgOu {
  height: 30px;
  border-radius: 15px;
  min-width: auto;
}
button.FieldStatisticsToolbar_button__1TgOu:hover {
  background-color: #eeeeee;
}
p.FieldStatisticsToolbar_buttonText__1nk0f {
  font-size: 14px;
  margin-left: 8px;
  white-space: nowrap;
}

div.FieldStatistics_grid__3M2Gu {
  height: 100%;
}
div.FieldStatistics_grid__3M2Gu div[aria-selected='true'] {
  outline: none;
}
div.FieldStatistics_grid__3M2Gu > div:first-child > div:first-child {
  padding-left: 12px;
}
div.FieldStatistics_grid__3M2Gu > div:first-child > div {
  padding: 0;
}
div.FieldStatistics_grid__3M2Gu > div:not(:first-child) > div {
  display: flex;
  -webkit-user-select: all;
          user-select: all;
}
div.FieldStatistics_gridContainer__hd36G {
  overflow: hidden;
}

div.SortableHeaderCell_titleWrapper__3vykR {
  min-width: 0;
}
p.SortableHeaderCell_headerTitle__1YDcw {
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
p.SortableHeaderCell_headerSubtitle__38wYX {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: #a7a9ac;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.SortableHeaderCell_containerView__18bPw {
  overflow: hidden;
}
input.SortableHeaderCell_inputEnabled__3M2YQ {
  inline-size: auto;
  padding: 4px;
  margin: 4px;
  font-size: 14px;
}
input.SortableHeaderCell_inputDisabled__GYynY {
  inline-size: auto;
  padding: 4px;
  margin: 4px;
  font-size: 14px;
  opacity: 0.35;
}

div.FieldStatisticsEditContainer_dialogContent__3dDBy {
  padding: 0 8px;
}

div.FieldIntegrationMappingDialog_paper__2sN1E {
  padding: 12px 100px;
}
div.FieldIntegrationMappingDialog_content__W9YMM {
  padding: 0;
  overflow: hidden;
}
hr.FieldIntegrationMappingDialog_divider__hQw6c {
  margin: 8px 20px;
}
li.FieldIntegrationMappingDialog_sticky__1owpf {
  padding: 16px 16px;
  background-color: white;
}
ul.FieldIntegrationMappingDialog_list__2KVeC {
  width: 100%;
  overflow: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
ul.FieldIntegrationMappingDialog_list__2KVeC::-webkit-scrollbar {
  width: 8px;
}
ul.FieldIntegrationMappingDialog_list__2KVeC::-webkit-scrollbar-track {
  background: #eeeeee;
}
ul.FieldIntegrationMappingDialog_list__2KVeC::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
ul.FieldIntegrationMappingDialog_list__2KVeC::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
li.FieldIntegrationMappingDialog_listItem__1j-dF {
  padding: 2px 16px;
}
.FieldIntegrationMappingDialog_narrow-scrollbar__1Igi2 {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.FieldIntegrationMappingDialog_narrow-scrollbar__1Igi2::-webkit-scrollbar {
  width: 8px;
}
.FieldIntegrationMappingDialog_narrow-scrollbar__1Igi2::-webkit-scrollbar-track {
  background: #eeeeee;
}
.FieldIntegrationMappingDialog_narrow-scrollbar__1Igi2::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.FieldIntegrationMappingDialog_narrow-scrollbar__1Igi2::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

div.FieldIntegrationMappingCell_box__1GYvC {
  border-radius: 4px;
  border: 1px solid #000;
  box-sizing: border-box;
}
div.FieldIntegrationMappingCell_center__2rrZJ,
button.FieldIntegrationMappingCell_center__2rrZJ {
  width: 33% !important;
  height: 20px;
  background-color: white !important;
  border-radius: 10px;
}
button.FieldIntegrationMappingCell_center__2rrZJ:hover {
  background-color: #ffcdd2 !important;
}
p.FieldIntegrationMappingCell_text__1YdtU {
  max-width: 195px;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.FieldIntegrationMappingSelector_root__2Kcqp {
  cursor: pointer;
}
p.FieldIntegrationMappingSelector_text___KRPV {
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.FieldIntegrationMappingSelector_PopoverContainer__18z5Q {
  padding: 12px;
  overflow-y: hidden;
}
div.FieldIntegrationMappingSelector_ListItemText__lYVKY {
  font-size: 0.8rem;
  margin: 0;
}
div.FieldIntegrationMappingSelector_ListItemSecondaryAction__1Rwzl {
  margin-right: -8px;
}
div.FieldIntegrationMappingSelector_ListItemSecondaryAction__1Rwzl button {
  padding: 8px;
}
div.FieldIntegrationMappingSelector_ListItemSecondaryAction__1Rwzl button svg {
  font-size: 1.2rem;
}
ul.FieldIntegrationMappingSelector_list__1BFjS {
  width: 100%;
  overflow: auto;
  height: 30vh;
  padding: 0;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
ul.FieldIntegrationMappingSelector_list__1BFjS::-webkit-scrollbar {
  width: 8px;
}
ul.FieldIntegrationMappingSelector_list__1BFjS::-webkit-scrollbar-track {
  background: #eeeeee;
}
ul.FieldIntegrationMappingSelector_list__1BFjS::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
ul.FieldIntegrationMappingSelector_list__1BFjS::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
div.FieldIntegrationMappingSelector_header__2ivr3 {
  font-weight: 400;
  font-size: 0.9rem;
  color: #a7a9ac;
  overflow: hidden;
  white-space: nowrap;
}
div.FieldIntegrationMappingSelector_item__2G7oU {
  font-weight: 400;
  font-size: 0.9rem;
  overflow: hidden;
  white-space: nowrap;
}
.FieldIntegrationMappingSelector_narrow-scrollbar__3NX5X {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.FieldIntegrationMappingSelector_narrow-scrollbar__3NX5X::-webkit-scrollbar {
  width: 8px;
}
.FieldIntegrationMappingSelector_narrow-scrollbar__3NX5X::-webkit-scrollbar-track {
  background: #eeeeee;
}
.FieldIntegrationMappingSelector_narrow-scrollbar__3NX5X::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.FieldIntegrationMappingSelector_narrow-scrollbar__3NX5X::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

p.FieldStatisticsContainer_title__Ri0cB {
  font-size: 12px;
  line-height: 1.2;
}
p.FieldStatisticsContainer_subtitle__2tHV5 {
  font-size: 12px;
  line-height: 1.2;
  color: #a7a9ac;
}
button.FieldStatisticsContainer_iconButton__2GNgF {
  padding: 4px;
  height: 32px;
}
div.FieldStatisticsContainer_absolute__3a1uw {
  position: absolute;
  height: 100%;
  width: calc(100% - 16px);
}
div.FieldStatisticsContainer_shortcutWrapper__2pZay {
  pointer-events: none;
}
div.FieldStatisticsContainer_shortcutWrapper__2pZay * {
  pointer-events: all;
}

