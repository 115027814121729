.Root {
  .narrow-scrollbar();

  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: all;
  direction: rtl;

  border-radius: 4px !important;

  * {
    direction: ltr;
  }
}

.Paper {
  pointer-events: all;
  background-color: white;
  border-radius: 0 !important;
  overflow: hidden;
  box-sizing: border-box;
}

.CloseButton {
  padding-top: 0;
  padding-bottom: 0;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@grey100: #f5f5f5;
@grey400: #bdbdbd;
@red300: #e57373;

.Loading {
  @primary: @grey100;
  @secondary: @grey400;

  background: repeating-linear-gradient(
    -45deg,
    @primary,
    @primary 20px,
    @secondary 20px,
    @secondary 40px
  );
  background-size: 56px 56px; /* This is unique for this background, need to find a pattern and develop a formula */
  -webkit-animation: slide 20s infinite linear forwards;
  -moz-animation: slide 20s infinite linear forwards;
  animation: slide 20s infinite linear forwards;

  opacity: 0.5;
}

.Error {
  @primary: @red300;
  @secondary: @grey400;

  background: repeating-linear-gradient(
    -45deg,
    @primary,
    @primary 20px,
    @secondary 20px,
    @secondary 40px
  );
  background-size: 56px 56px; /* This is unique for this background, need to find a pattern and develop a formula */

  opacity: 0.5;
}

@-webkit-keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@-moz-keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes slide {
  0% {
    background-position: 0% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
