.root {
  pointer-events: none;
  z-index: 2;
}

.childContainer {
  & > * {
    pointer-events: all;
  }
}
