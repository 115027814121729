.root {
  position: relative;
  margin-top: 16px;
}

.border {
  position: relative;
  top: 0;
  border: 1.5px solid #212121;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.title {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1;
  top: 0;
  transform: translateY(-50%);
  left: 10px;
  background-color: white;
}

div.datePicker,
.inputRoot,
input.input {
  cursor: pointer !important;
  width: 100%;

  & fieldset {
    border: hidden;
  }
}

input.input {
  padding: 8px;
}
