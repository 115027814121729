/*==================
    Color Palette
==================*/
.Title {
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Name {
  color: #78a22f;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  padding-left: 8px;
}
.Slider {
  overflow: hidden !important;
  padding: 0 24px 24px;
  box-sizing: border-box;
}
.Slide {
  height: 100%;
  padding: 0 !important;
}
div.Content {
  padding: 0;
}
.Language {
  position: absolute;
  top: 24px;
  transform: translateY(-50%);
  right: 24px;
}
.FloatingBox {
  position: absolute;
  top: -24px;
  transform: translateY(-100%);
  left: 0;
  background-color: white;
  padding: 12px;
  box-sizing: border-box;
}
div.dialogPaper {
  overflow-y: visible;
}
