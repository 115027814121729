.Root {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  pointer-events: none;
  height: 1px;
  transform: translateY(-50%);

  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
