p.text {
  color: black;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8;
}

.checkbox {
  padding: 0 !important;
  display: block;
}
