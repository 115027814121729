div.Paper {
  padding: 0 12px 0 12px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;

  .narrow-scrollbar();
}

ul.ListRoot {
  box-sizing: border-box;
}

div.ButtonRoot {
  display: flex;
  margin-right: 8px;
}

li.Subheader {
  padding-left: 0;
  padding-right: 6px;
}

li.Divider {
  margin: 0 -12px;
}

label.LabelRoot {
  justify-content: space-between;
  width: 100%;
  margin-left: 0;
}

div.UploadPaper {
  margin-bottom: 12px;
}

div.ListItemRoot {
  width: ~'calc(100% - 0px)';
}

div.ListItemText {
  & > * {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

div.ListItemSecondary {
  display: flex;
  align-content: center;
  align-items: center;
  pointer-events: none;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
