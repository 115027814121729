@grey300: #e0e0e0;
@green100: #c8e6c9;

.Tile {
  user-select: none;
  pointer-events: all;
  margin: 8px;
  height: 160px;
  width: 260px;

  &:hover {
    & .TitleBar {
      display: flex;
    }
  }
}

.TileBar {
  user-select: none;
  display: none;
}

.TileImage {
  width: 75%;
  margin: auto;
  max-height: 75%;
  max-width: 75%;
}

.Icon {
  color: white;
  margin-right: 12px;
  user-select: none;
}

.Paper {
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: @grey300;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 12px;

  &:hover {
    background-color: @green100;
  }
}
