.Wrapper {
  bottom: -4px;
  transform: translate(0%, 100%);
  font-size: 14px;
  position: absolute;
  padding: 0;
  text-align: center;
  width: 120px;
  height: 28px;
  cursor: default;

  & > input {
    padding: 0 5px;
    transform: translate(-50%, 0);
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 1px;
    margin: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    font-weight: bold;
    cursor: text;
  }
}

.Unit {
  position: absolute;
  box-sizing: border-box;
  top: 8px;
  font-size: 12px;
  pointer-events: none;
  user-select: none;
  color: #9e9e9e;
  left: 8px;
}
