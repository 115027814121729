.box {
  min-width: 180px;
  max-width: 180px;
  height: 56px;
}

div.paper {
  pointer-events: all;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
}

p.text {
  padding: 0 8px;
  text-transform: none;
  font-weight: 500;
}

button.button {
  width: 100%;
  height: 48px;
}
