div.titleWrapper {
  min-width: 0;
}

p.headerTitle {
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p.headerSubtitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: #a7a9ac;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.containerView {
  overflow: hidden;
}

input.inputEnabled {
  inline-size: auto;
  padding: 4px;
  margin: 4px;
  font-size: 14px;
}

input.inputDisabled {
  inline-size: auto;
  padding: 4px;
  margin: 4px;
  font-size: 14px;
  opacity: 0.35;
}
