div.Menu,
div.Paper {
}

ul.MenuInner {
}

div.Paper {
  transform: translateX(-12px) translateY(24px) !important;
  width: 300px;
}

div.Search {
  box-shadow: none;
  height: 35px;
  padding: 8px;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
