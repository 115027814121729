div.paper {
  padding: 12px 100px;
}

div.content {
  padding: 0;
  overflow: hidden;
}

hr.divider {
  margin: 8px 20px;
}

li.sticky {
  padding: 16px 16px;
  background-color: white;
}

ul.list {
  width: 100%;
  overflow: auto;
  .narrow-scrollbar();
}

li.listItem {
  padding: 2px 16px;
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
