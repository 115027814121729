div.DialogContent {
  .narrow-scrollbar();
}

.narrow-scrollbar {
  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #757575;
  }
}
